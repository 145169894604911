import { useContext, useEffect, useState } from "react";
import PageInfo from "./PageInfo";
import PageModal, { PageHeaderModal } from "../PageModal";
import TabBar from "../TabBar";
import Button from "../Button";
import Card, { CardBody, CardTitle } from "../Card";
import { CartContext } from "../../contexts/CartContext";
import images from "../../assets/images";
import { formatNumber } from "../../utils/currency";
import FoodInCart from "../Cart/FoodInCart";
import * as alertNotify from "../../utils/alertNotify";
import {
  API_CREATE_BOOKING_ORDER,
  API_GET_ESTIMATE_FEE,
  ENUM_METHOD_GET,
  ENUM_METHOD_POST,
  ENUM_PROJECT_ID_JAVA_ORDER,
  PAGE_CHECKOUT_LOCATION,
  PAGE_INFO_LOCATION,
  PAGE_PAYMENT_LOCATION,
} from "../../contexts/constains";
import * as axiosTemplate from "../../utils/axiosTemplate";
import { MenuContext } from "../../contexts/MenuContext";
import Payment from "./Payment";
import { useLocation, useNavigate } from "react-router-dom";
import { lockScroll } from "../../utils/scrollBody";
import { OrderContext } from "../../contexts/OrderContext";

// const shipping = [
//     {
//         id: 1,
//         label: 'Nhanh',
//         time: '15 phút',
//         price: '16,000',
//     },
//     {
//         id: 2,
//         label: 'Tiết kiệm',
//         time: '25 phút',
//         price: '13,000',
//     },
// ];

// Before Change
// const Checkout = ({ onClose, onOpenFoodUpdateModal }) => {
//     const { hash } = useLocation();
//     const navigate = useNavigate();

//     const {
//         cartState: { infoCustomer },
//         updateCustomer,
//     } = useContext(CartContext);

//     const {
//         menuState: { restaurant },
//     } = useContext(MenuContext);

//     let [infoPageModal, setInfoPageModal] = useState(false);
//     let [checkoutModal, setCheckoutModal] = useState(false);
//     let [paymentModal, setPaymentModal] = useState(false);
//     let [dataPayment, setDataPayment] = useState({});
//     // let timer;

//     const handleClose = (type = 0) => {
//         type === 0 ? navigate(-1) : navigate(`/?qr_code=${restaurant.qr_code}`);
//         // window.location.hash = '#';
//         // if (infoPageModal === true && checkoutModal === true) {
//         //     setInfoPageModal(false);
//         //     return;
//         // }

//         // if (paymentModal === true && checkoutModal === true) {
//         //     setPaymentModal(false);
//         //     return;
//         // }

//         // infoPageModal === true && setInfoPageModal(false);
//         // setCheckoutModal(false);
//         // timer = setTimeout(() => {
//         //     onClose();
//         // }, 300);
//     };

//     const handleOpenPageInfo = () => {
//         setInfoPageModal(true);
//         window.location.hash = PAGE_INFO_LOCATION;
//     };

//     const handleOpenPaymentModal = (data) => {
//         setDataPayment(data);
//         setPaymentModal(true);
//         window.location.hash = PAGE_PAYMENT_LOCATION;
//     };

//     const handleSubmitInfo = (data) => {
//         if (checkoutModal === false) {
//             setCheckoutModal(true);
//             window.location.hash = PAGE_CHECKOUT_LOCATION;
//         } else if (infoPageModal === true) {
//             setInfoPageModal(false);
//         }

//         updateCustomer(data);
//     };

//     useEffect(() => {
//         if (hash !== `#${PAGE_INFO_LOCATION}` && infoPageModal) {
//             setInfoPageModal(false);
//         } else if (hash === `#${PAGE_INFO_LOCATION}` && !infoPageModal) {
//             setInfoPageModal(true);
//         }

//         if (hash !== `#${PAGE_CHECKOUT_LOCATION}` && checkoutModal) {
//             setCheckoutModal(false);
//         } else if (hash === `#${PAGE_CHECKOUT_LOCATION}` && !checkoutModal) {
//             setCheckoutModal(true);
//         }

//         if (hash !== `#${PAGE_PAYMENT_LOCATION}` && paymentModal) {
//             setPaymentModal(false);
//         } else if (hash === `#${PAGE_PAYMENT_LOCATION}` && !paymentModal) {
//             setPaymentModal(true);
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [hash]);

//     useEffect(() => {
//         if (infoCustomer.isUpdate === 0) {
//             setInfoPageModal(true);
//             window.location.hash = PAGE_INFO_LOCATION;
//         } else {
//             setCheckoutModal(true);
//             window.location.hash = PAGE_CHECKOUT_LOCATION;
//         }

//         // return () => clearTimeout(timer);
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);

//     useEffect(() => {
//         if (infoPageModal) lockScroll(PAGE_INFO_LOCATION);
//     }, [infoPageModal]);

//     useEffect(() => {
//         if (checkoutModal) lockScroll(PAGE_CHECKOUT_LOCATION);
//     }, [checkoutModal]);

//     return (
//         <>
//             <Content
//                 active={checkoutModal}
//                 onOpenPaymentModal={handleOpenPaymentModal}
//                 onClose={() => handleClose(1)}
//                 onOpenInfoPage={handleOpenPageInfo}
//                 onOpenFoodUpdateModal={onOpenFoodUpdateModal}
//             />
//             <PageInfo active={infoPageModal} onClose={() => handleClose(0)} onNext={handleSubmitInfo} />
//             <Payment active={paymentModal} onClose={() => handleClose(0)} data={dataPayment} />
//         </>
//     );
// };

// Before Change
// const Content = ({ active, onClose, onOpenInfoPage, onOpenFoodUpdateModal, onOpenPaymentModal }) => {
//     const navigate = useNavigate();
//     const {
//         cartState: { carts, total, infoCustomer },
//         removeFood,
//         clearFood,
//     } = useContext(CartContext);

//     const { pushOrder } = useContext(OrderContext);

//     const {
//         menuState: { restaurant, restaurant_active },
//     } = useContext(MenuContext);

//     // eslint-disable-next-line no-unused-vars
//     const [shippingCur, setShippingCur] = useState(null);
//     const [estimateFee, setEstimateFee] = useState({ loading: true, amount: 0, delivery_id: 0 });
//     const [paymentMethod, setPaymentMethod] = useState(0);

//     const handleRemoveFoodInCart = (id) => {
//         alertNotify.push('Bạn có muốn xóa lựa chọn này ra khỏi giỏ hàng ?', 'Đóng', 'Đồng ý', () => removeFood(id));
//     };

//     const handleSubmitOrder = async () => {
//         if (carts.length === 0) {
//             alertNotify.push('Giỏ hàng của bạn đang trống. Vui lòng chọn món và thử lại !', 'Đóng');
//             return;
//         }

//         alertNotify.push('Bạn chắc chắn muốn gửi đơn hàng này cho chúng tôi ?', 'Đóng', 'Đồng ý', () => sendOrder());
//     };

//     const sendOrder = async () => {
//         let foods = carts.map((item) => ({
//             id: item.id,
//             quantity: item.quantity,
//             addition_foods: item.option.map((addition) => ({ id: addition.id, quantity: addition.quantity, note: '' })),
//             note: item.note,
//         }));
//         let data = {
//             customer_name: infoCustomer.name,
//             phone: infoCustomer.phone,
//             address: infoCustomer.address.address.label,
//             note: infoCustomer.address.note,
//             foods: foods,
//             restaurant_id: restaurant.id,
//             restaurant_brand_id: restaurant_active.id,
//             branch_id: restaurant_active.branches[0].id,
//             payment_method: paymentMethod,
//             shipping_lat: infoCustomer.address.address.location[1],
//             shipping_lng: infoCustomer.address.address.location[0],
//             restaurant_third_party_delivery_id: estimateFee.delivery_id,
//         };

//         const project = ENUM_PROJECT_ID_JAVA_ORDER;
//         const method = ENUM_METHOD_POST;

//         const res = await axiosTemplate.post(project, method, API_CREATE_BOOKING_ORDER, data);
//         switch (res.status) {
//             case 200:
//                 pushOrder({ id: res.data.customer_order_id, count_food: foods.length, branch_address: restaurant_active.branches[0].address_full_text });
//                 clearFood();

//                 alertNotify.push('Đơn hàng của bạn đã được gửi thành công. Vui lòng chờ đợi phản hồi !', 'Đóng', 'Xem đơn hàng', () => {});
//                 navigate('/order/' + res.data.customer_order_id);
//                 // if (res.data?.qr_code) {
//                 //     onOpenPaymentModal(res.data);
//                 // }
//                 break;
//             case 500:
//                 alertNotify.push('Lỗi dữ liệu', 'Đóng');
//                 break;
//             default:
//                 alertNotify.push(res.message, 'Đóng');
//                 break;
//         }
//     };

//     useEffect(() => {
//         setShippingCur(1);
//     }, []);

//     useEffect(() => {
//         if (active) {
//             if (restaurant_active.setting.delivery_type) {
//                 const fetchEstimateFee = async () => {
//                     const project = ENUM_PROJECT_ID_JAVA_ORDER;
//                     const method = ENUM_METHOD_GET;
//                     const api = API_GET_ESTIMATE_FEE.sprintf(
//                         infoCustomer.address.address.location[1],
//                         infoCustomer.address.address.location[0],
//                         infoCustomer.address.address.label,
//                         restaurant_active.branches[0].id,
//                     );

//                     const res = await axiosTemplate.get(project, method, api, { loading: false });
//                     switch (res.status) {
//                         case 200:
//                             if (res.data.length > 0) {
//                                 setEstimateFee({ loading: false, amount: res.data[0].shipping_fee, delivery_id: res.data[0].restaurant_third_party_delivery_id });
//                             } else {
//                                 setEstimateFee({ loading: false, amount: 0, delivery_id: 0 });
//                             }
//                             break;
//                         default:
//                             setEstimateFee({ loading: false, amount: 0, delivery_id: 0 });
//                             break;
//                     }
//                 };

//                 fetchEstimateFee();
//             } else if (restaurant_active.setting.delivery_type === 0) {
//                 setEstimateFee({ loading: false, amount: restaurant_active.setting.shipping_fee_amount, delivery_id: 0 });
//             } else {
//                 setEstimateFee({ loading: false, amount: 0, delivery_id: 0 });
//             }
//         } else {
//             setEstimateFee({ loading: true, amount: 0, delivery_id: 0 });
//         }

//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [active]);

//     return (
//         <PageModal active={active}>
//             <PageHeaderModal title={restaurant?.name || ''} onClose={onClose} subTitle={''}></PageHeaderModal>
//             <div id="checkout-modal-scroll" className="h-[calc(100vh-124px)] pb-32 overflow-y-auto bg-gray-100 space-y-2">
//                 <div className="bg-gray-000">
//                     <Card className="!space-y-2">
//                         <CardTitle>
//                             <div className="relative flex items-center">
//                                 <i className="fi-rr-location-alt absolute left-0 text-base text-orange-brand-900"></i>
//                                 <h2 className="text-base font-medium mx-6">Thông tin nhận hàng</h2>
//                                 <i className="fi-rr-edit absolute right-0 text-base text-orange-brand-900" onClick={onOpenInfoPage}></i>
//                             </div>
//                         </CardTitle>
//                         <CardBody>
//                             <div className="relative ml-6">
//                                 <div className="flex flex-col text text-gray-600 gap-1">
//                                     {infoCustomer.isUpdate === 1 && (
//                                         <>
//                                             <span style={{ overflowWrap: 'anywhere' }}>
//                                                 <span>{infoCustomer.name}</span>&nbsp;|&nbsp;<span>{infoCustomer.phone}</span>
//                                             </span>
//                                             <span style={{ overflowWrap: 'anywhere' }}>{infoCustomer.address.address.label}</span>
//                                             {infoCustomer.address.note && (
//                                                 <div className="flex gap-1">
//                                                     <i className="fi-rr-document text-orange-brand-900 translate-y-[3px]"></i>{' '}
//                                                     <span style={{ overflowWrap: 'anywhere' }}>{infoCustomer.address.note}</span>
//                                                 </div>
//                                             )}
//                                         </>
//                                     )}
//                                 </div>
//                             </div>
//                         </CardBody>
//                     </Card>
//                 </div>
//                 {/* <div className="bg-gray-000">
//                     <Card className="!space-y-2">
//                         <CardTitle>
//                             <div className="relative flex items-center">
//                                 <i className="fi-rr-school-bus absolute left-0 text-base text-orange-brand-900"></i>
//                                 <h2 className="text-base font-medium mx-6">Thông tin vận chuyển</h2>
//                             </div>
//                         </CardTitle>
//                         <CardBody>
//                             <div className="ml-6 space-y-2">
//                                 {shipping.map((item) => (
//                                     <div
//                                         key={item.id}
//                                         onClick={() => setShippingCur(item.id)}
//                                         className={`flex justify-between items-center cursor-pointer p-2 rounded-b4 border ${
//                                             shippingCur === item.id ? 'border-orange-brand-900' : 'border-trans bg-gray-100'
//                                         }`}
//                                     >
//                                         <div>
//                                             <b>{item.label}</b>
//                                             &nbsp; &bull; &nbsp;
//                                             <span>{item.time}</span>
//                                         </div>
//                                         <div>{item.price}</div>
//                                     </div>
//                                 ))}
//                             </div>
//                         </CardBody>
//                     </Card>
//                 </div> */}
//                 <div className="bg-gray-000">
//                     <Card>
//                         <CardTitle>
//                             <div className="flex justify-between items-center">
//                                 <h2 className="text-base font-medium">Tóm tắt đơn hàng</h2>
//                                 <div>
//                                     <span className="cursor-pointer text-xs font-medium tracking-[0.4px] text-orange-brand-900 uppercase" onClick={onClose}>
//                                         Thêm món
//                                     </span>
//                                 </div>
//                             </div>
//                         </CardTitle>
//                         <CardBody>
//                             {carts.length <= 0 && (
//                                 <div className="w-full text-center">
//                                     <img src={images.emptyCartImg} alt="" className="w-70 h-70 md:w-60 md:h-60 object-cover mx-auto" />
//                                 </div>
//                             )}
//                             {carts.length > 0 &&
//                                 carts.map((food, index) => (
//                                     <FoodInCart key={index} data={food} onOpenFoodUpdateModal={() => onOpenFoodUpdateModal(true, food)} onRemoveFood={() => handleRemoveFoodInCart(food.id)} />
//                                 ))}
//                         </CardBody>
//                     </Card>
//                 </div>
//                 <div className="bg-gray-000">
//                     <Card>
//                         <CardBody>
//                             <ul className="tracking-[0.25px] space-y-4">
//                                 <div className="flex justify-between text-base font-medium">
//                                     <span>Tổng tạm tính</span>
//                                     <span>{formatNumber(total)}</span>
//                                 </div>
//                                 <div className="flex justify-between text-base font-normal">
//                                     <span>
//                                         Phí vận chuyển
//                                         <br />
//                                         {restaurant_active.setting.delivery_type === 0 && <span className="text-sm text-orange-brand-900">(Áp dụng cho tất cả đơn hàng)</span>}
//                                     </span>
//                                     <span>{estimateFee.loading ? 'Đang tính phí' : estimateFee.amount === 0 ? 'Miễn phí' : formatNumber(estimateFee.amount)}</span>
//                                 </div>
//                             </ul>
//                         </CardBody>
//                     </Card>
//                 </div>
//                 <div className="bg-gray-000">
//                     <Card>
//                         <CardTitle>
//                             <div className="flex justify-between items-center">
//                                 <h2 className="text-base font-medium">Hình thức thanh toán</h2>
//                             </div>
//                         </CardTitle>
//                         <CardBody>
//                             <div className="relative">
//                                 <div className="space-y-2">
//                                     {/* <label htmlFor="payment-method-1" className="flex flex-col">
//                                         <div className="flex items-center gap-4">
//                                             <input type="radio" id="payment-method-1" name="payment-method" value="1" checked={paymentMethod === 1} onChange={() => setPaymentMethod(1)} />
//                                             <span>Thanh toán chuyển khoản (Quét mã VietQR)</span>
//                                         </div>
//                                         <div>
//                                             <img src={images.payosLogo} alt="" className="h-8" />
//                                         </div>
//                                     </label> */}
//                                     <label htmlFor="payment-method-2" className="flex flex-col">
//                                         <div className="flex items-center gap-4">
//                                             <input type="radio" id="payment-method-2" name="payment-method" value="2" checked={paymentMethod === 0} onChange={() => setPaymentMethod(0)} />
//                                             <span>Thanh toán khi nhận hàng</span>
//                                         </div>
//                                     </label>
//                                 </div>
//                                 <span className="absolute top-0 bottom-0 -left-5 w-[2px] bg-gray-600"></span>
//                             </div>
//                         </CardBody>
//                     </Card>
//                 </div>
//             </div>
//             <TabBar height="h-[124px]">
//                 <div className="flex justify-between">
//                     <span>Tổng thanh toán</span>
//                     <div className="flex flex-col items-end">
//                         <b className="text-lg">{estimateFee.loading ? 'Đang tính toán' : formatNumber(total + estimateFee.amount)}</b>
//                         {/* <del>2,655,000</del> */}
//                     </div>
//                 </div>
//                 <Button onClick={() => !estimateFee.loading && handleSubmitOrder()} disabled={estimateFee.loading}>
//                     <div className={`${estimateFee.loading ? 'text-gray-600' : 'text-green-brand-600'} text-base uppercase font-bold mx-auto `}>Đặt đơn</div>
//                 </Button>
//             </TabBar>
//         </PageModal>
//     );
// };

const Checkout = ({ onClose, onOpenFoodUpdateModal }) => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const {
    cartState: { infoCustomer },
    updateCustomer,
  } = useContext(CartContext);

  const {
    menuState: { restaurant },
  } = useContext(MenuContext);

  let [infoPageModal, setInfoPageModal] = useState(false);
  let [checkoutModal, setCheckoutModal] = useState(false);
  let [paymentModal, setPaymentModal] = useState(false);
  let [dataPayment, setDataPayment] = useState({});
  // let timer;

  const handleClose = (type = 0) => {
    type === 0 ? navigate(-1) : navigate(`/?qr_code=${restaurant.qr_code}`);
    // window.location.hash = '#';
    // if (infoPageModal === true && checkoutModal === true) {
    //     setInfoPageModal(false);
    //     return;
    // }

    // if (paymentModal === true && checkoutModal === true) {
    //     setPaymentModal(false);
    //     return;
    // }

    // infoPageModal === true && setInfoPageModal(false);
    // setCheckoutModal(false);
    // timer = setTimeout(() => {
    //     onClose();
    // }, 300);
  };

  const handleOpenPageInfo = () => {
    setInfoPageModal(true);
    window.location.hash = PAGE_INFO_LOCATION;
  };

  const handleOpenPaymentModal = (data) => {
    setDataPayment(data);
    setPaymentModal(true);
    window.location.hash = PAGE_PAYMENT_LOCATION;
  };

  const handleSubmitInfo = (data) => {
    if (checkoutModal === false) {
      setCheckoutModal(true);
      window.location.hash = PAGE_CHECKOUT_LOCATION;
    } else if (infoPageModal === true) {
      setInfoPageModal(false);
    }

    updateCustomer(data);
  };

  useEffect(() => {
    if (hash !== `#${PAGE_INFO_LOCATION}` && infoPageModal) {
      setInfoPageModal(false);
    } else if (hash === `#${PAGE_INFO_LOCATION}` && !infoPageModal) {
      setInfoPageModal(true);
    }

    if (hash !== `#${PAGE_CHECKOUT_LOCATION}` && checkoutModal) {
      setCheckoutModal(false);
    } else if (hash === `#${PAGE_CHECKOUT_LOCATION}` && !checkoutModal) {
      setCheckoutModal(true);
    }

    if (hash !== `#${PAGE_PAYMENT_LOCATION}` && paymentModal) {
      setPaymentModal(false);
    } else if (hash === `#${PAGE_PAYMENT_LOCATION}` && !paymentModal) {
      setPaymentModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (infoCustomer.isUpdate === 0) {
      setInfoPageModal(true);
      window.location.hash = PAGE_INFO_LOCATION;
    } else {
      setCheckoutModal(true);
      window.location.hash = PAGE_CHECKOUT_LOCATION;
    }

    // return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (infoPageModal) lockScroll(PAGE_INFO_LOCATION);
  }, [infoPageModal]);

  useEffect(() => {
    if (checkoutModal) lockScroll(PAGE_CHECKOUT_LOCATION);
  }, [checkoutModal]);

  return (
    <>
      <Content
        active={checkoutModal}
        onOpenPaymentModal={handleOpenPaymentModal}
        onClose={() => handleClose(1)}
        onOpenInfoPage={handleOpenPageInfo}
        onOpenFoodUpdateModal={onOpenFoodUpdateModal}
      />
      <PageInfo
        active={infoPageModal}
        onClose={() => handleClose(0)}
        onNext={handleSubmitInfo}
      />
      <Payment
        active={paymentModal}
        onClose={() => handleClose(0)}
        data={dataPayment}
      />
    </>
  );
};

const Content = ({
  active,
  onClose,
  onOpenInfoPage,
  onOpenFoodUpdateModal,
  onOpenPaymentModal,
}) => {
  const navigate = useNavigate();
  const {
    cartState: { carts, total, infoCustomer },
    removeFood,
    clearFood,
  } = useContext(CartContext);

  const { pushOrder } = useContext(OrderContext);

  const {
    menuState: { restaurant, restaurant_active },
  } = useContext(MenuContext);

  // eslint-disable-next-line no-unused-vars
  const [shippingCur, setShippingCur] = useState(null);
  const [estimateFee, setEstimateFee] = useState({
    loading: true,
    amount: 0,
    delivery_id: 0,
    error: false,
  });
  const [paymentMethod, setPaymentMethod] = useState(0);

  const handleRemoveFoodInCart = (id) => {
    alertNotify.push(
      "Bạn có muốn xóa lựa chọn này ra khỏi giỏ hàng ?",
      "Đóng",
      "Đồng ý",
      () => removeFood(id)
    );
  };

  const handleSubmitOrder = async () => {
    if (carts.length === 0) {
      alertNotify.push(
        "Giỏ hàng của bạn đang trống. Vui lòng chọn món và thử lại !",
        "Đóng"
      );
      return;
    }

    alertNotify.push(
      "Bạn chắc chắn muốn gửi đơn hàng này cho chúng tôi ?",
      "Đóng",
      "Đồng ý",
      () => sendOrder()
    );
  };

  const sendOrder = async () => {
    let foods = carts.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      addition_foods: item.option.map((addition) => ({
        id: addition.id,
        quantity: addition.quantity,
        note: "",
      })),
      note: item.note,
    }));
    let data = {
      customer_name: infoCustomer.name,
      phone: infoCustomer.phone,
      address: infoCustomer.address.address.label,
      note: infoCustomer.address.note,
      foods: foods,
      restaurant_id: restaurant.id,
      restaurant_brand_id: restaurant_active.id,
      branch_id: restaurant_active.branches[0].id,
      payment_method: paymentMethod,
      shipping_lat: infoCustomer.address.address.location[1],
      shipping_lng: infoCustomer.address.address.location[0],
      restaurant_third_party_delivery_id: estimateFee.delivery_id,
    };

    const project = ENUM_PROJECT_ID_JAVA_ORDER;
    const method = ENUM_METHOD_POST;

    const res = await axiosTemplate.post(
      project,
      method,
      API_CREATE_BOOKING_ORDER,
      data
    );
    switch (res.status) {
      case 200:
        pushOrder({
          id: res.data.customer_order_id,
          count_food: foods.length,
          branch_address: restaurant_active.branches[0].address_full_text,
        });
        clearFood();

        alertNotify.push(
          "Đơn hàng của bạn đã được gửi thành công. Vui lòng chờ đợi phản hồi !",
          "Đóng",
          "Xem đơn hàng",
          () => {}
        );
        navigate("/order/" + res.data.customer_order_id);
        // if (res.data?.qr_code) {
        //     onOpenPaymentModal(res.data);
        // }
        break;
      case 500:
        alertNotify.push("Lỗi dữ liệu", "Đóng");
        break;
      default:
        alertNotify.push(res.message, "Đóng");
        break;
    }
  };

  useEffect(() => {
    setShippingCur(1);
  }, []);

  useEffect(() => {
    if (active) {
      if (restaurant_active.setting.delivery_type) {
        const fetchEstimateFee = async () => {
          const project = ENUM_PROJECT_ID_JAVA_ORDER;
          const method = ENUM_METHOD_GET;
          const api = API_GET_ESTIMATE_FEE.sprintf(
            infoCustomer.address.address.location[1],
            infoCustomer.address.address.location[0],
            infoCustomer.address.address.label,
            restaurant_active.branches[0].id
          );

          const res = await axiosTemplate.get(project, method, api, {
            loading: false,
          });
          switch (res.status) {
            case 200:
              if (res.data.length > 0) {
                setEstimateFee({
                  loading: false,
                  amount: res.data[0].shipping_fee,
                  delivery_id: res.data[0].restaurant_third_party_delivery_id,
                  error: false,
                });
              } else {
                setEstimateFee({
                  loading: false,
                  amount: 0,
                  delivery_id: 0,
                  error: false,
                });
              }
              break;
            case 502:
              setEstimateFee({
                loading: false,
                amount: 0,
                delivery_id: 0,
                error: true,
              });
              alertNotify.push(res.message, "Đóng");
              break;
            default:
              setEstimateFee({
                loading: false,
                amount: 0,
                delivery_id: 0,
                error: false,
              });
              break;
          }
        };

        fetchEstimateFee();
      } else if (restaurant_active.setting.delivery_type === 0) {
        setEstimateFee({
          loading: false,
          amount: restaurant_active.setting.shipping_fee_amount,
          delivery_id: 0,
          error: false,
        });
      } else {
        setEstimateFee({
          loading: false,
          amount: 0,
          delivery_id: 0,
          error: false,
        });
      }
    } else {
      setEstimateFee({
        loading: true,
        amount: 0,
        delivery_id: 0,
        error: false,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  console.log("estimateFee", estimateFee);

  return (
    <PageModal active={active}>
      <PageHeaderModal
        title={restaurant?.name || ""}
        onClose={onClose}
        subTitle={""}
      ></PageHeaderModal>

      <div
        id="checkout-modal-scroll"
        className="container mx-auto h-[calc(100vh-124px)] pt-6 pb-32 overflow-y-auto bg-[#FFFFFF] bg-opacity-70 grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6"
      >
        <div className="flex flex-col gap-2 order-2 md:order-1">
          {/* Info Nhận hàng */}
          <div className="bg-white mt-4 rounded-lg p-4 shadow-cart">
            <div className="relative flex items-center mb-4">
              <i className="fi-rr-location-alt absolute left-0 text-base text-orange-brand-900"></i>
              <h2 className="text-[18px] font-medium mx-6 text-[#001416]">
                Thông tin nhận hàng
              </h2>
              <i
                className="fi-rr-edit absolute right-0 text-base text-orange-brand-900"
                onClick={onOpenInfoPage}
              ></i>
            </div>

            <div className="relative ml-6">
              <div className="flex flex-col text text-gray-600 gap-3">
                {infoCustomer.isUpdate === 1 && (
                  <>
                    <span style={{ overflowWrap: "anywhere" }}>
                      <span className="text-[#001416] font-medium text-base">
                        Họ tên người nhận:{" "}
                      </span>
                      {infoCustomer.name}
                    </span>
                    <span style={{ overflowWrap: "anywhere" }}>
                      <span className="text-[#001416] font-medium text-base">
                        Số điện thoại:{" "}
                      </span>
                      {infoCustomer.phone}
                    </span>
                    <span style={{ overflowWrap: "anywhere" }}>
                      <span className="text-[#001416] font-medium text-base">
                        Địa chỉ:{" "}
                      </span>{" "}
                      {infoCustomer.address.address.label}
                    </span>
                    {infoCustomer.address.note && (
                      <div className="flex gap-1">
                        <i className="fi-rr-document text-orange-brand-900 translate-y-[4px]"></i>
                        <span style={{ overflowWrap: "anywhere" }}>
                          <span className="text-[#001416] font-medium text-base">
                            Ghi chú:
                          </span>{" "}
                          {infoCustomer.address.note}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Hình thức thanh toán */}
          <div className="bg-white mt-4 rounded-lg p-4 shadow-cart">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-[18px] font-medium mx-6 text-[#001416]">
                Hình thức thanh toán
              </h2>
            </div>

            <div className="relative ml-6 pb-2">
              <div className="space-y-4">
                {/* <label htmlFor="payment-method-1" className="flex flex-col">
                                    <div className="flex items-center gap-2 relative">
                                        <input type="radio" id="payment-method-1" className="accent-orange-500" name="payment-method" value="1" checked={paymentMethod === 1} onChange={() => setPaymentMethod(1)} />
                                        <img src={paymentMethod === 1 ? images.radioCheckedImg : images.radioNormalImg} alt="" className='absolute top-0 -left-1'/>
                                        <span>Thanh toán qua qua QR Code</span>
                                    </div>
                                </label> */}
                <label htmlFor="payment-method-2" className="flex flex-col">
                  <div className="flex items-center gap-2 relative">
                    <input
                      type="radio"
                      id="payment-method-2"
                      name="payment-method"
                      value="2"
                      checked={paymentMethod === 0}
                      onChange={() => setPaymentMethod(0)}
                    />
                    <img
                      src={
                        paymentMethod === 0
                          ? images.radioCheckedImg
                          : images.radioNormalImg
                      }
                      alt=""
                      className="absolute top-0 -left-1"
                    />
                    <span>Thanh toán khi nhận hàng</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 order-1 md:order-0">
          {/* Tóm tắt đơn hàng */}
          <div className="bg-white mt-4 rounded-lg p-4 shadow-cart">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-[18px] font-medium text-[#001416]">
                Tóm tắt đơn hàng
              </h2>
              <div>
                <span
                  className="cursor-pointer text-xs font-medium tracking-[0.4px] text-orange-brand-900 uppercase"
                  onClick={onClose}
                >
                  Thêm món
                </span>
              </div>
            </div>

            {carts.length <= 0 && (
              <div className="w-full text-center">
                <img
                  src={images.emptyCartImg}
                  alt=""
                  className="w-70 h-70 md:w-60 md:h-60 object-cover mx-auto"
                />
              </div>
            )}

            <div className="md:overflow-y-auto md:max-h-[500px] no-scrollbar">
              {carts.length > 0 &&
                carts.map((food, index) => (
                  <FoodInCart
                    key={index}
                    data={food}
                    onOpenFoodUpdateModal={() =>
                      onOpenFoodUpdateModal(true, food)
                    }
                    onRemoveFood={() => handleRemoveFoodInCart(food.id)}
                  />
                ))}
            </div>

            {/* Tổng tạm tính */}
            <ul className="tracking-[0.25px] space-y-4 mt-4">
              <div className="flex justify-between text-base font-medium">
                <span>Tổng tạm tính</span>
                <span>{formatNumber(total)}</span>
              </div>
              <div className="flex justify-between text-base font-normal">
                <span>
                  Phí vận chuyển
                  <br />
                  {restaurant_active.setting.delivery_type === 0 && (
                    <span className="text-sm text-orange-brand-900">
                      (Áp dụng cho tất cả đơn hàng)
                    </span>
                  )}
                </span>
                <span>
                  {estimateFee.loading
                    ? "Đang tính phí"
                    : estimateFee.error
                    ? <span className="text-red-brand-700">Không hỗ trợ</span>
                    : estimateFee.amount === 0
                    ? "Miễn phí"
                    : formatNumber(estimateFee.amount)}
                </span>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <TabBar className="container mx-auto" height="h-[124px]">
        <div className="flex justify-between">
          <span className="text-base">Tổng thanh toán</span>
          <div className="flex flex-col items-end">
            <b className="text-lg">
              {estimateFee.loading
                ? "Đang tính toán"
                : formatNumber(total + estimateFee.amount)}
            </b>
          </div>
        </div>

        <Button
          className={`bg-custom-gradient hover:bg-custom-gradient-hover ${
            estimateFee.loading || estimateFee.error ? "opacity-50" : ""
          }`}
          onClick={() =>
            !estimateFee.loading && !estimateFee.error && handleSubmitOrder()
          }
          disabled={estimateFee.loading || estimateFee.error}
        >
          <div className={`text-white text-base uppercase font-bold mx-auto `}>
            Thanh toán ngay
          </div>
        </Button>
      </TabBar>
    </PageModal>
  );
};

export default Checkout;
