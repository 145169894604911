import Slider from "react-slick";
import images from "../../assets/images";

function Category({ categoryList, setCategoryList, setCategoryValue, isLoadContent }) {
  function handleClickCategory(categoryId) {
    setCategoryValue(categoryId);
    setCategoryList(
      categoryList.map((category) => ({
        ...category,
        is_active: category.id === categoryId ? 1 : 0,
      }))
    );
  }

  const NextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="custom-arrow bg-white shadow-lg rounded-full p-1 md:p-2 text-gray-500 hover:bg-gray-100 absolute -right-4 md:-right-10 top-1/2 transform  -translate-y-1/2 z-[5]"
    >
      <img src={images.categoryNextImg} alt="Next" />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="custom-arrow bg-white shadow-lg rounded-full p-1 md:p-2 text-gray-500 hover:bg-gray-100 absolute -left-4 md:-left-10 top-1/2 transform -translate-y-1/2 z-[5]"
    >
      <img src={images.categoryPrevImg} alt="Previous" style={{ filter: 'brightness(0.5)' }} />
    </button>
  );

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 7,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  if(!isLoadContent) return null;

  return (
    <div id="category-group" className="pt-20">
      <div className="container mx-auto relative">
        <Slider {...settings}>
          {categoryList && categoryList.length > 0
            ? categoryList.map((item) => {
                const isActive = item.is_active;
                const textColor = isActive
                  ? "text-orange-brand-900"
                  : "text-[#374151]";
                const borderBottom = isActive
                  ? "border-b-2 border-[#F36A0F]"
                  : "";
                const hoverEffect = isActive
                  ? ""
                  : "hover:bg-[#F36A0F] hover:text-white transition-colors";

                return (
                  <div
                    key={item.id}
                    onClick={() => handleClickCategory(item.id)}
                    className={`py-6 px-2 text-center cursor-pointer font-bold ${textColor} ${borderBottom} ${hoverEffect}`}
                  >
                    {item.name}
                  </div>
                );
              })
            : null}
        </Slider>
      </div>
    </div>
  );
}

export default Category;